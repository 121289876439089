// Footer.js
import React from 'react';
import { FaArrowUp } from 'react-icons/fa';

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="grid grid-cols-2 md:flex justify-center p-8 bg-black text-white">
      <div className="flex items-center">
        <span className="bg-gray-200 text-black rounded-full p-2 h-8 flex items-center justify-center m-2">
          &copy;
        </span>
        <span className="text-sm sm:text-md">Copyright 2024. Pulsely, Inc. All Rights Reserved.</span>
      </div>
      {/* <div className="bg-blue-500 p-4 m-4 text-white font-semibold rounded-lg text-center">
        <a href="https://pulseco.ai" className="text-white rounded cursor-pointer">
          Try for Free!
        </a>
      </div> */}
      <div className="flex items-center space-x-10">
        <div className='text-center'>
        <a
          href="privacy-policy"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          <p className='text-white'>Privacy Policy</p>
        </a>
        </div>
        <div>
        <a
          href="terms-and-conditions-url"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          <p className='text-white'>Terms and Conditions</p>
        </a>
        </div>
        <div>
        <button
          className="text-white p-4 rounded-lg"
          onClick={scrollToTop}
        >
          <FaArrowUp />
        </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
