import React from 'react';

// TODO: Replace this with feed from doc 

function PrivacyPolicy() {

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Privacy Policy</h1>
      <p className='mb-4'>

        This Privacy Policy ("Policy") describes how Pulsely, Inc. ("we," "us," or
        "our") collects, uses, discloses, and otherwise processes your personal
        information in connection with your use of our website, mobile
        applications, and/or Software as a Service ("SaaS") offerings
        (collectively, the "Services").
      </p>

      <h2 className='font-bold text-xl'>1. Information We Collect</h2>
      <p className='mb-4'>
        We collect several different types of information for various purposes
        to provide and improve our Services to you.
      </p>
      <ul className='mb-4'>
        <li>
          <b>Personal Information:</b> This information can directly or
          indirectly identify an individual and includes:
          <ul className='mb-4'>
            <li>Contact information: name, email address, phone number, physical address.</li>
            <li>Account information: username, password (hashed and salted), security questions and answers.</li>
            <li>Billing information: payment card details (through a secure third-party processor), billing address.</li>
          </ul>
        </li>
        <li className='mb-2'>
          <b>Usage Data:</b> information collected automatically when you use
          the Services, such as IP address, device type, operating system,
          browsing activity within the Services, usage statistics, and crash logs.
        </li>
        <li className='mb-2'>
          <b className='mr-1'>Google User Data</b>
          Pulsely, Inc's use and transfer to any other app of information received from Google APIs will adhere to
          <span className='text-blue-500 font-underline ml-1 mr-1'>
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              rel='noopener noreferrer'
            >
              Google API Services User Data Policy,
            </a>
          </span>
          including the Limited Use requirements.
        </li>
        <li>
          <b>Information you provide through user-generated content features:</b> feedback, reviews, comments, and other content you submit through the Services.
        </li>
      </ul>
      <h2 className='font-bold text-lg'>Retention and Deletion of Google User Data:</h2>
      <ul className='mb-4'>
        <li>
          <ul className='mb-4'>
            <li className='mb-2'>
              We store your email address to display it in the user interface, enabling you to easily identify the account from which emails are being sent. Additionally, we securely store a refresh token to enable the continued ability to send emails from your Google account without requiring re-authentication.
            </li>
            <li>
              <b>Retention Period:</b>
              <ul className="list-disc pl-4">
                <li className="text-gray-700">We retain your Google user data for as long as it is necessary to provide our services to you, including maintaining your email address and refresh token.</li>
              </ul>
            </li>
            <li>
              <b>Deletion of Data:</b>
              <ul className="list-disc pl-4">
                <li className="text-gray-700">You have the right to request the deletion of your Google user data at any time. Upon receiving your request, we will promptly delete all stored data, including your email address and refresh token, from our systems. To request deletion, please contact us at hello@pulseco.ai.</li>
              </ul>
            </li>
            <li className='mt-2'>
              You can revoke our access to your Google account at any time through your Google account settings. If you do so, our services will no longer have access to necessary details to enable sending of drafts, and you will need to re-authorize access if you wish to use our email-sending features again.
            </li>
          </ul>
        </li>
      </ul>
      <ul className='mb-4'>
        <li>
          <b>Non-Personal Information:</b> This information does not directly
          identify an individual and includes:
          <ul className='mb-4'>
            <li>Aggregated Data: Derived from your Personal Information but does not readily identify you. For example, we may collect data on user behavior within the Services to analyze trends and improve functionality.</li>
            <li>Device Information: device identifiers, browser type, operating system, and internet service provider (ISP).</li>
          </ul>
        </li>
      </ul>
      <h2 className='font-bold text-xl'>2. How We Collect Information</h2>
      <p className='mb-4'>We collect information through various methods:</p>
      <ul className='mb-4'>
        <li>
          <b>Information You Provide:</b> You directly provide Personal
          Information when you register for an account, subscribe to a plan,
          submit a contact form, or use interactive features of the Services.
        </li>
        <li>
          <b>Information Collected Automatically:</b> We use cookies and other
          tracking technologies to collect Usage Data and Device Information.
        </li>
      </ul>

      <h2 className='font-bold text-xl'>3. Cookies and Tracking Technologies</h2>
      <p className='mb-4'>
        We use several cookies and other tracking technologies to collect and
        store information to improve your experience and analyze website traffic.
        These technologies include:
      </p>
      <ul className='mb-4'>
        <li>
          <b>Cookies:</b> Small data files stored on your device that remember
          information about your activity on the Services.
        </li>
        <li>
          <b>Web Beacons:</b> Electronic images that track how you navigate the
          Services.
        </li>
        <li>
          <b>Log Files:</b> Records activity on the Services, including IP address,
          browser type, internet service provider, referring and exit pages, and
          date/time stamps.
        </li>
      </ul>

      <h2 className='font-bold text-xl'>4. How We Use Your Information</h2>
      <p className='mb-4'>We use the information we collect for various purposes, including:</p>
      <ul className='mb-4'>
        <li>
          <b>Provide and Maintain the Services:</b> Fulfill your requests, process
          your transactions, manage your account, and deliver the Services.
        </li>
        <li>
          <b>Improve the Services:</b> Analyze usage trends, personalize your
          experience, develop new features, and ensure the Services function
          properly.
        </li>
        <li>
          <b>Marketing and Communications:</b> Send you promotional emails or
          marketing communications about our Services, subject to your consent
          where required by law.
        </li>
        <li>
          <b>Security and Compliance:</b> Protect the Services from fraud, misuse,
          and unauthorized access, and comply with legal and regulatory
          requirements.
        </li>
      </ul>

      <h2 className='font-bold text-xl'>AI Policy & Practices:</h2>
      <ul className='mb-4'>
        <li>
          <ul className='mb-4'>
            <li className='mb-2'>
              Pulsely, inc utilizes GPT-4-turbo, 4o, and Llama-3-sonar AI models to enhance user experience and optimize performance. These models process limited user provided data to format information and generate text.
            </li>
            <li>
              <b>Data Shared:</b>
              <ul class="list-disc pl-4">
                <li class="text-gray-700">Search Attributes: Industry, and other non-identifying search criteria.</li>
                <li class="text-gray-700">Outreach Email Generation: Prospect-related information such as industry, location, and other relevant data points for user requested email generation.</li>
                <li class="text-gray-700">User Information: Limited to user name for email signature in outreach.</li>
              </ul>

            </li>
            <li className='mt-2'>We are committed to responsible AI usage and avoid sharing Personally Identifiable Information (PII) where possible. If you have further questions on these policies and practices, please contact us at hello@pulseco.ai</li>
          </ul>
        </li>
      </ul>
      <h2 className='font-bold text-xl'>5. Disclosure of Your Information</h2>
      <p className='mb-4'>We may disclose your information in the following circumstances:</p>
      <ul className='mb-4'>
        <li>
          <b>Service Providers:</b> We may share your information with third-party
          vendors who provide services on our behalf, such as payment processing,
          data analytics, and customer support. These vendors are obligated to
          use your information only to perform services for us and in accordance
          with this Policy.
        </li>
        <li>
          <b>Legal Requirements:</b> We may disclose your information if required
          by law, subpoena, or court order to comply with legal or regulatory
          requirements, or to protect our rights or property.
        </li>
        <li>
          <b>Business Transfers:</b> In the event of a merger, acquisition, or
          asset sale, your information may be transferred to the successor
          company. We will provide notice before your information is transferred
          and becomes subject to a different privacy policy.
        </li>
      </ul>

      <h2 className='font-bold text-xl'>6. Your Choices and Rights</h2>
      <p className='mb-4'>You have several choices regarding your information:</p>
      <ul className='mb-4'>
        <li>
          <b>Access and Update:</b> You may access and update your account
          information through your account settings.
        </li>
        <li>
          <b>Marketing Communications:</b> You can unsubscribe from marketing
          communications by following the unsubscribe instructions in the emails
          we send.
        </li>
        <li>
          <b>Data Subject Rights:</b> Depending on your jurisdiction, you may have
          additional rights regarding your information, such as the right to
          request deletion or restriction of processing.
        </li>
      </ul>

      <h2 className='font-bold text-xl'>7. Data Security</h2>
      <p className='mb-4'>
        We take reasonable steps to protect your information from unauthorized
        access, disclosure, alteration, or destruction. However,
        no security
        measures are perfect, and we cannot guarantee the security of your
        information.

      </p>

      <h2 className='font-bold text-xl'>8. Children's Privacy</h2>
      <p className='mb-4'>
        Our Services are not directed to children under 16. We do not knowingly
        collect personal information from children under 16.
      </p>

      <h2 className='font-bold text-xl'>9. International Data Transfers</h2>
      <p className='mb-4'>
        Your information may be transferred to and processed in countries other
        than your own, which may have different data protection laws. We will take
        steps to ensure that your information is treated in accordance with this
        Policy and applicable laws.
      </p>

      <h2 className='font-bold text-xl'>10. California Privacy Rights</h2>
      <p className='mb-4'>
        If you are a California resident, you have certain rights under the
        California Consumer Privacy Act ("CCPA"). You can request
        to know what
        personal information we have collected, used, or disclosed about you,
        and you can request that we delete your personal information. You can
        also opt-out of the sale of your personal information.
      </p>

      <h2 className='font-bold text-xl'>11. Changes to this Policy</h2>
      <p className='mb-4'>
        We may update this Policy from time to time. We will notify you of any
        changes by posting the new Policy on our website.
        Your continued use of
        the Services
        after the revised Policy is posted constitutes your
        acceptance of the changes.
      </p>

      <h2 className='font-bold text-xl'>12. Contact Us</h2>
      <p className='mb-4'>
        If you have any questions about this Policy or your information, please
        contact us at:
      </p>
      <a href="mailto:hello@pulseco.ai">hello@pulseco.ai</a>

      <h2 className='font-bold text-xl'>13. Entire Agreement</h2>
      <p className='mb-4'>
        This Policy constitutes the entire agreement between you and us
        regarding your use of the Services and supersedes all prior or
        contemporaneous communications and proposals, whether
        oral or written.
      </p>

      <h2 className='font-bold text-xl'>14. Disclaimer</h2>
      <p className='mb-4'>
        This Policy is intended to provide information about our privacy practices
        and does not constitute a contract or create any legal rights or
        obligations.
      </p>
      <p className='mb-4'>Policy Effective from: 7/8/2024</p>
      <p className='mb-4'>Policy last updated: 7/8/2024</p>
    </div>
  );
}

export default PrivacyPolicy;
