import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import React from 'react';
import Header from './sections/header.js';
import Search from './sections/search.js';
import MapSection from './sections/mapSection.js';
import DataCards from './sections/pulses.js';
import Drafts from './sections/drafts.js';
import Contact from './sections/contact.js';
import Footer from './sections/footer.js';
import PrivacyPolicy from './docs/PrivacyPolicy.js';

function HomePage() {
  return (
    <>
      <Header />
      <Search />
      <div className="min-h-screen overflow-auto">
        <MapSection />
        <DataCards />
        <Drafts />
        <Contact />
      </div>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
