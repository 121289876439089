import React from 'react';

function MapSection() {
  return (
    <div className="py-10 bg-white flex flex-col items-center">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-10">
        Search live for people in natural language
      </h2>
      <img src={`${process.env.PUBLIC_URL}/map-view.png`} alt="Pulse"
           className="min-w-3.5 max-w-120 mb-4 mt-10"/>
      <div className="w-full flex justify-center mt-10">
        <div className="w-1 h-44 bg-gray-200 animate-pulse"></div>
      </div>
    </div>
  );
}

export default MapSection;
