import React from 'react';

function DataCards() {
  return (
    <div className="py-10 bg-white w-full flex flex-col items-center">
      <h2 className="text-2xl font-bold text-center mb-10 p-2">
        Automate personalized outreach with <span className='text-blue-600'> Pulses </span>
      </h2>
      <h3 className="text-center text-2xl font-bold text-blue-500 mb-10">Review and send. Meetings booked.</h3>
      <img src={`${process.env.PUBLIC_URL}/drafts.png`} alt="Pulse" className="w-full sm:w-11/12 max-w-4xl mb-4 mt-10" />
      <div className="w-full flex justify-center mt-10">
        <div className="w-1 h-44 bg-gray-200 animate-pulse"></div>
      </div>
    </div>
  );
}

export default DataCards;
