import React from 'react';

function Search() {

  return (
    <div className="flex flex-col items-center bg-white h-screen">
      <img src={`${process.env.PUBLIC_URL}/pulse-logo.png`} alt="Pulse"
          className="sm:mt-5 mb-4
          w-7/12 sm:w-7/12 md:w-1/2 max-w-md"/>
      <div
        className="p-4 border border-gray-100 shadow-lg rounded-full m-5
        w-11/12 sm:w-10/12 md:w-9/12 max-w-screen-md">
        <h2 className="text-center text-xl sm:text-2xl">Find me every VP of Sales in technology in Texas</h2>
      </div>
      {/* <div className="mt-6 bg-blue-600 text-white py-4 px-8 rounded-xl mb-5 hover:animate-pulse">
        <a href="https://pulseco.ai" className="text-xl font-semibold py-2 px-4 rounded cursor-pointer">
          Try our technology for free!
        </a>
      </div> */}
      <div className="flex-grow w-full flex justify-center mt-10">
        <div className="w-1 rounded-full bg-gray-200 animate-pulse"></div>
      </div>
    </div>
  );
}

export default Search;
