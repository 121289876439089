import React from 'react';

function DataCards() {
  return (
    <div className="py-10 bg-white w-full flex flex-col items-center">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-5">
        Build and attach live AI pulses on people
      </h2>
      <h3 className="text-4xl sm:text-5xl font-bold text-blue-500 m-5">Track detailed live data</h3>
      <img src={`${process.env.PUBLIC_URL}/cards.png`} alt="Pulse" className="w-11/12 max-w-4xl mb-5" />
      <div className="w-full flex justify-center mt-10">
        <div className="w-1 h-44 bg-gray-200 animate-pulse"></div>
      </div>
    </div>
  );
}

export default DataCards;
