import React from 'react';

const Contact = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="relative w-full h-full max-w-4xl flex items-center justify-center">
        <img
          src={`${process.env.PUBLIC_URL}/pulse-icon.png`}
          alt="Background"
          className="w-4/5 max-w-md h-1/2"
        />
        <div className="absolute inset-0 bg-white bg-opacity-50 flex flex-col items-center justify-center">
          <h1 className="text-4xl font-bold mb-4 text-center">Questions?</h1>
          <h1 className="text-4xl font-bold mb-4 text-center">Chat with our team.</h1>
          <button className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
