import React from 'react';

function Header() {

  return (
<div className="py-6 bg-white px-6">
  <div className='flex items-center justify-center m-5'>
    <h1 className="mx-auto text-3xl font-semibold text-center sm:text-3xl p-2">
      The <span className="text-blue-600">future</span> of search and sales has arrived
    </h1>
    {/* <a href="https://pulseco.ai" className="w-auto bg-black text-white py-2 px-4 rounded cursor-pointertext-center">
      Login
    </a> */}
  </div>
</div>


  );
}

export default Header;
